import React from "react";
import {
	Box,
	Checkbox,
	FormControlLabel,
	Grid, TextField,

} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";

const SeoForm = (props) => {
	const {
		innerRef,
		initialValues,
	} = props;

	const handleSubmit = () => {
	}

	const handleChange = ({target}) => {
		const {name, value} = target;

		let newForm = {...innerRef?.current?.values || {}};
		newForm[name] = value;

		innerRef.current?.setValues(newForm);
	}
	const handleChangeBoolean = ({target}, value) => {
		const {name} = target;

		let newForm = {...innerRef.current.values};
		newForm[name] = value;

		innerRef.current.setValues(newForm);
	}
	const handleChangeAutocomplete = (value, name) => {
		let newForm = {...innerRef.current.values};
		newForm[name] = value?.value || null;

		innerRef.current.setValues(newForm);
	}

	return (
		<Formik
			innerRef={innerRef}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{(props) => {
				const {
					values,
					errors,
					touched
				} = props;

				return (
					<Box px={2} py={2} bgcolor="white" borderRadius={2}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									value={values.slug}
									error={Boolean(touched.slug && errors.slug)}
									helperText={touched.slug && errors.slug}
									name="slug"
									label="Ссылка"
									fullWidth
									size='small'

									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={values.metaTitle}
									error={Boolean(touched.metaTitle && errors.metaTitle)}
									helperText={touched.metaTitle && errors.metaTitle}
									name="metaTitle"
									label="SEO Title"
									fullWidth
									size='small'

									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={values.metaKeywords}
									error={Boolean(touched.metaKeywords && errors.metaKeywords)}
									helperText={touched.metaKeywords && errors.metaKeywords}
									name="metaKeywords"
									label="SEO Keywords"
									fullWidth
									size='small'

									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={values.metaDescription}
									error={Boolean(touched.metaDescription && errors.metaDescription)}
									helperText={touched.metaDescription && errors.metaDescription}
									name="metaDescription"
									label="SEO Description"
									fullWidth
									size='small'

									onChange={handleChange}
								/>
							</Grid>
						</Grid>
					</Box>
				)
			}}
		</Formik>
	)
}

const validationSchema = Yup.object().shape({
	slug: Yup.string().required('Заполните поле')
});

export default React.memo(SeoForm)