import React from "react";
import {
	Autocomplete,
	Box,
	Tooltip,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {Formik} from "formik";
import {IMaskInput} from "react-imask";
import * as Yup from "yup";
import {AutocompleteSites, CKEditor} from "../../../../../components";
import agent from "../../../../../agent/agent";
import search from "../../../../../layouts/Default/components/Header/components/Search";

const MainForm = (props) => {
	const {
		innerRef,
		brandId,
		initialValues,
		characteristicGroupIds,
		onChangeProductExtraWebsites,
		onChangeProductCharacteristicGroup,

		isCollection
	} = props;
	const classes = useStyles();
	const isExchangeHash = Boolean(initialValues?.exchange_hash);

	const handleSubmit = (value, errors) => {
	}

	const handleChange = ({target}) => {
		const {name, value} = target;

		let newForm = {...innerRef?.current?.values || {}};
		newForm[name] = value;

		innerRef.current?.setValues(newForm);

		if (name === 'extraWebsites') {
			onChangeProductExtraWebsites(newForm);
		}
	}
	const handleChangeBoolean = ({target}, value) => {
		const {name} = target;

		let newForm = {...innerRef.current.values};
		newForm[name] = value;

		innerRef.current.setValues(newForm);
	}
	const handleChangeCharacteristicGroupId = ({target}) => {
		const {name, value} = target;

		let newForm = {...innerRef?.current?.values || {}};
		newForm[name] = value;

		innerRef.current?.setValues(newForm);
		onChangeProductCharacteristicGroup(newForm);
	}
	const handleChangeAutocomplete = (value, name) => {
		let newForm = {...innerRef.current.values};
		newForm[name] = value?.value || null;

		innerRef.current.setValues(newForm);
	}

	const _brandLabel = (value) => {
		return brandId.find((t) => t.value === value)?.label || ""
	}

	return (
		<Formik
			innerRef={innerRef}
			initialValues={initialValues}
			validationSchema={Boolean(!isCollection) ? validationSchema : validationSchemaCollection}
			onSubmit={handleSubmit}
		>
			{(props) => {
				const {
					values,
					errors,
					touched
				} = props;

				return (
					<Grid container rowSpacing="12px">
						<Grid xs={12} item>
							<Box px={2} py={2} bgcolor="white" borderRadius={2}>
								<Grid container spacing={4}>
									<Grid item xs={2}>
										<FormControlLabel
											control={<Checkbox/>}
											checked={values.status}
											value={values.status}
											name="status"
											label="Включено"
											onChange={handleChangeBoolean}
										/>
									</Grid>
									<Grid item xs={2}>
										<FormControlLabel control={<Checkbox/>} checked={values.is_hit} value={values.is_hit}
										                  name="is_hit" label="Хит продаж" onChange={handleChangeBoolean}/>
									</Grid>
									<Grid item xs={2}>
										<FormControlLabel
											control={<Checkbox/>}
											checked={values.is_special}
											value={values.is_special}
											name="is_special"
											label="Спец предложение"
											onChange={handleChangeBoolean}
										/>
									</Grid>
									<Grid item xs={2}>
										<FormControlLabel
											control={<Checkbox/>}
											checked={values.is_new}
											value={values.is_new}
											name="is_new"
											label="Новинка"
											onChange={handleChangeBoolean}
										/>
									</Grid>
									<Grid item xs={2}>
										<FormControlLabel
											control={<Checkbox/>}
											checked={values.is_not_available}
											value={values.is_not_available}
											name="is_not_available"
											label="Недоступен для продажи"
											onChange={handleChangeBoolean}
										/>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid xs={12} item>
							<Box px={2} py={4} bgcolor="white" borderRadius={2}>
								<Grid container spacing={4}>
									<Grid item xs={isCollection ? 4 : 12/5}>
										<TextField
											value={values.name}
											error={Boolean(touched.name && errors.name)}
											helperText={touched.name && errors.name}
											name="name"
											label="Название"
											fullWidth
											size='small'

											onChange={handleChange}
										/>
									</Grid>
									<Grid item xs={isCollection ? 4 : 12/5}>
										<TextField
											value={values.code}
											error={Boolean(touched.code && errors.code)}
											helperText={touched.code && errors.code}
											name="code"
											label="Артикул"
											fullWidth
											size='small'
											disabled={Boolean(values.is_product_set)}

											onChange={handleChange}
										/>
									</Grid>
									{Boolean(!isCollection) && (
										<>
											<Grid item xs={12/5}>
												<Tooltip
													arrow
													disableHoverListener={!Boolean(isExchangeHash)}
													disableFocusListener={!Boolean(isExchangeHash)}
													title={Boolean(isExchangeHash) ? 'Заполняется автоматически из прайсов поставщика' : ''}
												>
													<Box>
														<TextField
															value={values.sku}
															error={Boolean(touched.sku && errors.sku)}
															helperText={touched.sku && errors.sku}
															name="sku"
															label="Артикул поставщика"
															fullWidth
															size="small"
															disabled={isExchangeHash}

															onChange={handleChange}
														/>
													</Box>
												</Tooltip>
											</Grid>
											<Grid item xs={12/5}>
												<AutocompleteExtraProductSetParents
													initValues={values.extraProductSetParents}
													onChange={handleChangeAutocomplete}
												/>
											</Grid>
										</>
									)}
									<Grid item xs={isCollection ? 4 : 12/5} className={classes.autocomplete}>
										<AutocompleteSites
											value={values.extraWebsites}
											name="extraWebsites"
											error={Boolean(touched.extraWebsites && errors.extraWebsites)}
											helperText={touched.extraWebsites && errors.extraWebsites}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						{!isCollection && (
							<Grid xs={12} item>
								<Box px={2} py={4} bgcolor="white" borderRadius={2}>
									<Grid container spacing={4}>
										<Grid item xs={4}>
											<Tooltip
												arrow
												disableHoverListener={!Boolean(isExchangeHash)}
												disableFocusListener={!Boolean(isExchangeHash)}
												title={Boolean(isExchangeHash) ? 'Заполняется автоматически из прайсов поставщика' : ''}
											>
												<Box>
													<TextField
														value={String(values.price_special)}
														error={Boolean(touched.price_special && errors.price_special)}
														helperText={touched.price_special && errors.price_special}
														disabled={isExchangeHash}
														name="price_special"
														label="Цена со скидкой"
														fullWidth
														size='small'
														InputProps={{
															inputComponent: TextMaskCustom
														}}

														onChange={handleChange}
													/>
												</Box>
											</Tooltip>
										</Grid>
										<Grid item xs={4}>
											<Tooltip
												arrow
												disableHoverListener={!Boolean(isExchangeHash)}
												disableFocusListener={!Boolean(isExchangeHash)}
												title={Boolean(isExchangeHash) ? 'Заполняется автоматически из прайсов поставщика' : ''}
											>
												<Box>
													<TextField
														value={String(values.price_old)}
														error={Boolean(touched.price_old && errors.price_old)}
														helperText={touched.price_old && errors.price_old}
														disabled={isExchangeHash}
														name="price_old"
														label="Базовая цена"
														fullWidth
														size='small'
														InputProps={{inputComponent: TextMaskCustom}}
														onChange={handleChange}
													/>
												</Box>
											</Tooltip>
										</Grid>
										<Grid item xs={4}>
											<TextField
												value={values.supplier_price}
												error={Boolean(touched.supplier_price && errors.supplier_price)}
												helperText={touched.supplier_price && errors.supplier_price}
												name="supplier_price"
												label="Цена поставщика (евро/рубли)"
												fullWidth
												size='small'
												InputProps={{
													inputComponent: TextMaskCustom
												}}

												onChange={handleChange}
											/>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						)}
						<Grid xs={12} item>
							<Box px={2} py={4} bgcolor="white" borderRadius={2}>
								<Grid container spacing={4}>
									<Grid item xs>
										<Autocomplete
											fullWidth
											disablePortal
											name="brand_id"
											options={brandId}
											value={{value: values.brand_id, label: _brandLabel(values.brand_id)}}
											error={Boolean(touched.brand_id && errors.brand_id)}
											helperText={touched.brand_id && errors.brand_id}
											id="value"
											size="small"
											onChange={(event, value) => handleChangeAutocomplete(value, 'brand_id')}
											renderInput={(params) => <TextField
												{...params}
												InputLabelProps={{shrink: true}}
												label="Фабрика"
												fullWidth
											/>}
										/>
									</Grid>
									{Boolean(!isCollection) && (
										<Grid item xs>
											<FormControl
												fullWidth
												error={Boolean(touched.characteristic_group_id && errors.characteristic_group_id)}
												size="small"
											>
												<InputLabel>Тип товара</InputLabel>
												<Select
													value={values.characteristic_group_id}
													error={Boolean(touched.characteristic_group_id && errors.characteristic_group_id)}
													helperText={touched.characteristic_group_id && errors.characteristic_group_id}
													label="Тип товара"
													name="characteristic_group_id"
													fullWidth

													onChange={handleChangeCharacteristicGroupId}
												>
													{(characteristicGroupIds || []).map((item, idx) => {
														if (!!isCollection && !item.is_for_product_set) {
															return null
														}
														if (!isCollection && !!item.is_for_product_set) {
															return null
														}

														return (
															<MenuItem key={`sberbankTaxType-${idx}`} value={item.value}>
																{item.label}
															</MenuItem>
														)
													})}
												</Select>
												{Boolean(touched.characteristic_group_id && errors.characteristic_group_id) && (
													<FormHelperText>{touched.characteristic_group_id && errors.characteristic_group_id}</FormHelperText>
												)}
											</FormControl>
										</Grid>
									)}
									<Grid item xs>
										<TextField
											value={String(values.sort)}
											error={Boolean(touched.sort && errors.sort)}
											helperText={touched.sort && errors.sort}
											name="sort"
											label="Сортировка по умолчанию"
											fullWidth
											size='small'
											InputProps={{
												inputComponent: TextMaskCustom
											}}
											inputProps={{
												scale: 0
											}}

											onChange={handleChange}
										/>
									</Grid>
									{Boolean(!isCollection) && (
										<Grid item xs>
											<TextField
												value={String(values.quantity)}
												error={Boolean(touched.quantity && errors.quantity)}
												helperText={touched.quantity && errors.quantity}
												name="quantity"
												label="Кол-во"
												fullWidth
												size='small'
												InputProps={{
													inputComponent: TextMaskCustom
												}}
												inputProps={{
													scale: 0
												}}

												onChange={handleChange}
											/>
										</Grid>
									)}
								</Grid>
							</Box>
						</Grid>

						{Boolean(false) && (
							<Grid xs={12} item>
								<Box px={2} py={4} bgcolor="white" borderRadius={2}>
									<Grid container spacing={4}>
										<Grid item xs={12}>
											<CKEditor
												value={values.short_description}
												name="short_description"
												label="Краткое описание"
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12}>
											<CKEditor
												value={values.description}
												name="description"
												label="Описание"
												onChange={handleChange}
											/>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						)}
					</Grid>
				);
			}}
		</Formik>
	)
}
let timeOutSearch = null;
const AutocompleteExtraProductSetParents = React.memo((props) => {
	const {
		initValues,
		onChange
	} = props;
	const [values, setValues] = React.useState([]);
	const [options, setOptions] = React.useState([]);
	const [search, setSearch] = React.useState("");
	const [isLoading, setLoading] = React.useState(false);
	const classes = useStyles();

	React.useEffect(() => {
		(async () => {
			await handleUpdateListValues();
		})();
	}, [initValues]);

	const handleUpdateListValues = async () => {
		let newValues = [];
		await Promise.all(initValues.map(async (valueId) => {
			const response = await agent.get(`/admin/api-shop-product-set-parent/${valueId}?fields=id,name`).then((res) => {
				return res.data
			}).catch(() => {
				return {}
			})
			newValues.push(response);
		}));
		setValues(newValues);
	}
	const handleChangeSearch = (event, search) => {
		clearTimeout(timeOutSearch);

		setSearch(search);
		setLoading(Boolean(!!search));

		timeOutSearch = setTimeout(async () => {
			await handleGetListOptions();
		}, 1000)
	}
	const handleGetListOptions = async () => {
		if (!search) {
			return
		}
		const response = await agent.get(`/admin/api-shop-product-set-parent/search?fields=id,name&query=${search}`).then((res) => {
			return res.data
		}).catch(() => {
			return []
		})

		setOptions(response);
		setLoading(false);
	}
	const handleChange = (event, values) => {
		let valuesIds = [...values].map((t) => t.id);
		onChange({value: valuesIds}, 'extraProductSetParents');
	}


	const _getOptionLabel = (params) => {
		return params?.name || params?.id || ""
	}
	const _filterOptions = (options) => {
		return [...options].filter((t) => {
			return !Boolean((values || []).find((_t) => t.id === _t.id));
		})
	}

	return (
		<Autocomplete
			value={values}
			getOptionLabel={_getOptionLabel}
			options={options}
			loading={isLoading}
			onInputChange={handleChangeSearch}
			autoComplete={true}
			disableClearable={true}
			limitTags={2}
			multiple
			size="small"
			className={classes.autocomplete}
			inputValue={search}
			filterOptions={_filterOptions}
			filterSelectedOptions={true}
			onChange={handleChange}
			noOptionsText={Boolean(search) ? "Ничего не найдено" : "Начните печатать для поиска"}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Входит в коллекцию"
					placeholder="Начните печатать для поиска"
				/>
			)}
		/>
	)
})

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Заполните поле'),
	code: Yup.string().nullable().required('Заполните поле'),
	extraWebsites: Yup.array().min(1, 'Заполните поле'),
	characteristic_group_id: Yup.string().nullable().required('Заполните поле'),
	price_old: Yup
		.number()
		.nullable()
		.when('is_product_set', {
			is: 0,
			then: Yup.number().min(1, "Базовая стоимость не может пустой или 0").nullable().required('Заполните поле'),
		}),
	price_special: Yup
		.number()
		.nullable()
		.when(['price_old'], (price_old, schame) => {
			return schame.max(price_old, "\"Цена со скидкой\" не может быть больше \"Базовой стоимости\"")
		}),
});
const validationSchemaCollection = Yup.object().shape({
	name: Yup.string().required('Заполните поле'),
	code: Yup.string().nullable().required('Заполните поле'),
	characteristic_group_id: Yup.string().nullable().required('Заполните поле')
});
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
	const {onChange, ...other} = props;

	const handleOnAccept = (value) => {
		onChange({target: {name: props.name, value}})
	}

	return (
		<IMaskInput
			{...other}
			mask={Number}
			thousandsSeparator=" "
			radix="."

			inputRef={ref}
			unmask={true}
			onAccept={handleOnAccept}
		/>
	);
});
const useStyles = makeStyles(() => ({
	autocomplete: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		}
	}
}));

export default React.memo(MainForm)
export {
	AutocompleteExtraProductSetParents
}
