import React from "react";
import {
	Box,
	Grid,
	Tooltip
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Help as HelpIcon
} from "@mui/icons-material";
import TextField from "./form/TextField";
import Autocomplete from "./form/Autocomplete";
import AutocompleteCreated from "./form/AutocompleteCreated";

class SectionItems extends React.PureComponent {
	_gridXs = (characteristic) => {
		if (characteristic?.is_textarea) {
			return 12
		}
		if (this.props.name === "Размеры") {
			return 2.4
		}

		return 4
	}

	render () {
		const {
			extraValues,
			characteristicAssignments,
			sectionIndex,
			sectionID,
			isExchangeHash,
			initCharacteristics,

			classes,

			onChange
		} = this.props;


		return (
			<Grid container spacing={2}>
				{(characteristicAssignments).map((option, index) => {
					const isMultiple = Boolean(option?.characteristic?.is_multiple);
					const characteristicId = option.characteristic?.id || null;
					const findItem = extraValues[characteristicId];
					const value = Boolean(isMultiple) ? (findItem || []) : findItem;
					const disabled = Boolean(option?.characteristic?.is_readonly || false);
					const disabledEdit = Boolean(['length-cm', 'width-cm', 'height-cm', 'depth-cm'].includes(option?.characteristic?.external_id) && isExchangeHash);
					const isHiddenFromSlugs = ['tip-bannera'].includes((option?.characteristic?.slug || ''));

					if (disabled && (value === undefined || value === null || (Array.isArray(value) && value.length <= 0)) || isHiddenFromSlugs) {
						return null
					}
					if (disabled && (option?.characteristic?.decodedVariants || []).length <= 0) {
						return null
					}

					const isAutocompleteCreated = Boolean(option?.characteristic?.is_float || option?.characteristic?.is_integer);

					const initValues = initCharacteristics.find((t) => t.slug === option?.characteristic?.slug)?.values || [];

					return (
						<Grid
							key={`section-${ sectionIndex }-${ index }`}
							item
							xs={this._gridXs(option?.characteristic)}
						>
							<Box className={classes.option}>
								<Box className={classes.optionHead}>
									{ option?.characteristic?.name }

									{Boolean(option?.characteristic?.admin_note) && (
										<Tooltip arrow title={option?.characteristic?.admin_note}>
											<HelpIcon color="primary" sx={{fontSize: 20, marginLeft: "4px"}}/>
										</Tooltip>
									)}
								</Box>
								<Box className={classes.optionBody}>
									{Boolean(option?.characteristic?.is_textarea) && (
										<TextField
											value={value}
											name={characteristicId}
											disabled={disabled || disabledEdit}
											onChange={onChange}
										/>
									)}
									{Boolean(isAutocompleteCreated) && (
										<AutocompleteCreated
											value={value || null}
											initOptions={initValues}
											name={characteristicId}
											postUrl="/admin/api-shop-characteristic-variants"
											postName="characteristic_id"
											disabled={disabled || disabledEdit}
											onChange={onChange}

											characteristic={option?.characteristic}
										/>
									)}
									{Boolean(!option?.characteristic?.is_textarea && !isAutocompleteCreated) && (
										<Autocomplete
											value={Boolean(option?.characteristic?.is_multiple) ? (value || []) : (value || null)}
											initOptions={initValues}
											placeholder="Выберите"
											disabled={disabled || disabledEdit}
											name={characteristicId}
											multiple={isMultiple}
											onChange={onChange}
										/>
									)}
								</Box>
							</Box>
						</Grid>
					)
				})}
			</Grid>
		)
	}
}

const styles = {
	option: {},
	optionHead: {
		display: "flex",
		alignItems: "center",

		fontSize: 16,
		lineHeight: "20px",
		color: "#000000",
		marginBottom: 8
	},
	optionBody: {},
}
SectionItems = withStyles(styles)(SectionItems)

export default SectionItems
