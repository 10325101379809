import React from "react";
import {
	Autocomplete,
	Box, FormControl, Grid, MenuItem, Select, TextField,
	Typography
} from "@mui/material";
import agent from "../../../../../agent/agent";
import axios from "axios";

class Delivery extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			deliveries: [],
			pickupPoints: []
		}
	}

	componentDidMount = async () => {
		await this.getCheckoutData();
	}

	getCheckoutData = async () => {
		const checkoutData = await agent.get('/api/checkout/data?calculate=0').then((res) => {
			return res.data
		}).catch(() => {
			return {}
		})
		const deliveries = await agent.get('/admin/api-shop-delivery-methods').then((res) => {
			return (res.data || []).filter((t) => Boolean(t.is_active))
		}).catch(() => {
			return []
		});
		const pickupPoints = await agent.get('/api/checkout/pickup-points').then((res) => {
			return (res.data || []).find((t) => t.class === 'pickup')?.points || []
		}).catch(() => {
			return []
		})
		const config = await agent.get(`/api/configs`).then((res) => {
			return res.data
		}).catch(() => {
			return {}
		})

		this.setState({
			deliveries: deliveries,
			pickupPoints: pickupPoints
		})
	}

	changeData = ({target}) => {
		const {name, value} = target;

		let data = {...this.props.data};
		data[name] = value;
		data.delivery_address = "";

		this.props.onChange(data);
	}

	render() {
		const {
			deliveries,
			pickupPoints
		} = this.state;
		const {
			data
		} = this.props;

		return (
			<Box px={3} py={2} bgcolor="white" borderRadius="8px">

				<Typography variant="h4" sx={{marginBottom: "20px"}}>Информация о доставке</Typography>

				<Box mb="20px">
					<Typography display="block" variant="overline" sx={{marginBottom: "8px"}}>Способ доставки</Typography>
					<FormControl fullWidth>
						<Select
							value={data.delivery_method_id}
							name="delivery_method_id"
							onChange={this.changeData}
						>
							{deliveries.map((delivery) => (
								<MenuItem value={delivery.id}>{delivery.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>

				<VisibleContent visible={Boolean((data.delivery_method_id || "") === 6)}>
					<ContentCity
						data={data}
						onChange={this.props.onChange}
					/>
				</VisibleContent>
				<VisibleContent visible={Boolean((data.delivery_method_id || "") === 7)}>
					<ContentPickup
						data={data}
						pickupPoints={pickupPoints}
						onChange={this.props.onChange}
					/>
				</VisibleContent>

			</Box>
		)
	}
}

const VisibleContent = ({visible, children}) => {
	if (!visible) {
		return null
	}

	return children
}

let timeOutGetAddresses = null;
const ContentCity = React.memo((props) => {
	const {
		data,
		onChange
	} = props;
	const [addresses, setAddresses] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	const handleChangeSearch = async (event, search) => {
		clearTimeout(timeOutGetAddresses);

		if (!search) {
			return
		}

		timeOutGetAddresses = setTimeout(async () => {
			await handleGetAddresses(search);
		}, 1000);
	}
	const handleGetAddresses = async (query) => {
		setAddresses([]);
		setLoading(true);

		const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
		const dadataApiKey = 'a319086390ebb9a9283933316c00024ff249dda0';

		const response = await axios.post(url, {
			query
		}, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Token " + dadataApiKey,
			},
		}).then((res) => {
			return res.data?.suggestions || []
		}).catch(() => {
			return []
		})

		setAddresses(response);
		setLoading(false);
	}
	const handleChangeAddress = (event, value) => {
		let newData = {...data};
		newData.address = {
			'address': value.value,
			'unrestricted_address': value.unrestricted_value,
			'zip': value?.data?.postal_code || "",
			'region_name': value?.data?.region_with_type || "",
			'city_name': value?.data?.city_with_type || "",
			'street': value?.data?.street_with_type || "",
			'house': value?.data?.house || "",
			'flat': value?.data?.flat || value.flat || "",
			'intercom': value?.data?.flat || value.flat || "",
			'lat': value?.data?.geo_lat || "",
			'lon': value?.data?.geo_lon || ""
		}
		newData.delivery_address = value?.unrestricted_value;

		onChange(newData);
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography display="block" variant="overline" sx={{marginBottom: "4px"}}>Текущий адрес</Typography>
				<Typography variant="h6">{data.delivery_address}</Typography>
			</Grid>

			<Grid item xs={12}>
				<Typography display="block" variant="overline" sx={{marginBottom: "4px"}}>
					Начните печатать новый адрес
					<Typography variant="caption"> (вводить полный адрес с городом)</Typography>
				</Typography>
				<form autoComplete={false} action={null}>
					<Autocomplete
						getOptionLabel={(option) => option.unrestricted_value}
						options={addresses}
						loading={loading}
						size="small"
						autoComplete="false"
						filterOptions={(t) => [...t]}
						renderInput={(params) => (
							<TextField
								{...params}
							/>
						)}

						onInputChange={handleChangeSearch}
						onChange={handleChangeAddress}
					/>
				</form>
			</Grid>
		</Grid>
	)
})
const ContentPickup = React.memo((props) => {
	const {
		data,
		pickupPoints,
		onChange
	} = props;

	const handleChangeShop = (event) => {
		const {value} = event.target;
		const shop = pickupPoints.find((t) => t.code === value);

		let newData = {...data};
		newData.address = {
			street: shop.address,
			address: shop.address
		};
		newData.delivery_address = shop.address;

		onChange(newData);
	}

	return (
		<>
			<Box mb="20px">
				<Typography display="block" variant="overline" sx={{marginBottom: "4px"}}>Текущий адрес</Typography>
				<Typography variant="h6">{data.delivery_address}</Typography>
			</Box>

			<Typography display="block" variant="overline" sx={{marginBottom: "8px"}}>Выберите магазин для
				самовывоза</Typography>
			<FormControl fullWidth>
				<Select
					value={data.delivery_method_id}
					name="delivery_method_id"
					onChange={handleChangeShop}
				>
					{pickupPoints.map((point) => (
						<MenuItem value={point.code}>{point.address}</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	)
})

export default Delivery