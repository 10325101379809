import React, {PureComponent} from 'react';
import Dropzone from 'react-dropzone';
import {
	Box,
	Grid,
	IconButton,
	TextField,
	Typography,
	Tooltip,

	Backdrop,
	CircularProgress, Checkbox, ButtonGroup, Button, FormControlLabel,
	FormHelperText
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	BurstMode,
	Photo as PhotoIcon,
	Delete as DeleteIcon,
	HideImage as HideImageIcon,
	DragHandle as DragHandleIcon
} from '@mui/icons-material';
import agent from "../../../../../agent/agent";
import {
	SortableContainer,
	SortableElement,
	SortableHandle
} from "react-sortable-hoc";
import {
	DialogConfirmation
} from "../../../../../components";
import {checkValidImageLoading} from "../../../../../helper/check-valid-image-loading";
import {Notification, NotificationTypes} from "../../../../../common/Notification";

Array.prototype.insert = function ( index, item ) {
	this.splice( index, 0, item );
};

class DropZoneSecond extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedImages: [],

			isLoading: false
		}

		this.refDialogConfirmation = React.createRef();
	}

	onAddNewImages = async (newImages) => {
		this.setState({isLoading: true})

		let newServerImages = await Promise.all(newImages.map(async (image) => {
			const checkFile = await checkValidImageLoading(image);
			if (checkFile.error) {
				Notification({
					title: "",
					message: checkFile.error,
					type: NotificationTypes.error
				});
				return null
			} else {
				const formData = new FormData();
				formData.append("file", image);
				const response = await agent.post(`/api/admin/storage/upload-images`, formData).then((res) => {
					return res.data
				}).catch(() => {
					return {}
				})
				return response;
			}
		}));
		newServerImages = newServerImages.filter((t) => !!t);
		newServerImages = newServerImages.map((item) => {
			return {
				imageId: item?.image?.id,
				caption: null
			}
		})

		let images = [
			...this.props.images,
			...newServerImages,
		];

		this.props.onChange(images)
		this.onResetSelectedImages();

		this.setState({isLoading: false})
	}
	onDeleteImage = (index, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: "Вы действительно хотите удалить изображение?",
				acceptButtonTitle: "Да, удалить",
				cancelButtonTitle: "Нет, оставить",
				acceptButtonAction: this.onDeleteImage.bind(this, index, true)
			})

			return
		}

		let images = [...this.props.images];
		images.splice(index, 1);
		this.props.onChange(images);
		this.onResetSelectedImages();
	}
	onSetMainPhoto = (index, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: "Вы действительно хотите сделать изображение основным?",
				acceptButtonTitle: "Да, сделать",
				cancelButtonTitle: "Нет, вернуть",
				acceptButtonAction: this.onSetMainPhoto.bind(this, index, true)
			})

			return
		}

		this.props.onSetMainPhoto(index);
		this.onResetSelectedImages();
	}
	onHidePhoto = (index, isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: "Вы действительно хотите скрыть изображение?",
				acceptButtonTitle: "Да, сделать",
				cancelButtonTitle: "Нет, вернуть",
				acceptButtonAction: this.onHidePhoto.bind(this, index, true)
			})

			return
		}

		this.props.onHidePhoto(index);
		this.onResetSelectedImages();
	}
	onChangeCaptionImage = ({target}, index) => {
		const {value} = target;
		let images = [...this.props.images];
		images[index].caption = value;
		this.props.onChange(images)
	}

	onChangeSelectedImages = (imageIndex) => {
		let selectedImages = [...this.state.selectedImages];

		const findIndex = selectedImages.findIndex((t) => t === imageIndex);
		if (findIndex > -1) {
			selectedImages.splice(findIndex, 1);
		} else {
			selectedImages.push(imageIndex);
		}

		this.setState({ selectedImages });
	}
	onFullSelectedImages = () => {
		let selectedImages = [...this.state.selectedImages];

		if (selectedImages.length === this.props.images.length) {
			selectedImages = [];
		} else {
			selectedImages = this.props.images.map((t, index) => index);
		}

		this.setState({
			selectedImages
		})
	}
	onResetSelectedImages = () => {
		this.setState({
			selectedImages: []
		})
	}

	changeImagesSort = ({ oldIndex, newIndex }) => {
		let images = [...this.props.images];

		if (oldIndex === newIndex) {
			return
		}

		let imagesPrimary = {...images?.[oldIndex]};
		images.splice(oldIndex, 1);
		images.insert(newIndex, imagesPrimary);

		this.props.onChange(images)
	}

	// Множественные действия с фото
	multiDelete = async (isConfirm) => {
		const selectedImages = this.state.selectedImages;

		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы действительно хотите удалить ${this.state.selectedImages.length} изображение(ний)?`,
				acceptButtonTitle: "Да, удалить",
				cancelButtonTitle: "Нет, оставить",
				acceptButtonAction: this.multiDelete.bind(this, true)
			})

			return
		}

		const images = [...this.props.images]
			.filter((t, index) => !selectedImages.includes(index));
		this.props.onChange(images);
		this.onResetSelectedImages();
	}
	multiHidden = async (isConfirm) => {
		const selectedImages = this.state.selectedImages;

		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы действительно хотите скрыть ${selectedImages.length} изображение(ний)?`,
				acceptButtonTitle: "Да, удалить",
				cancelButtonTitle: "Нет, оставить",
				acceptButtonAction: this.multiHidden.bind(this, true)
			})

			return
		}

		// this.props.onHidePhotoMulti(selectedImages);
		this.onResetSelectedImages();
	}

	render () {
		const {
			images,
			classes
		} = this.props;
		const {
			selectedImages,
			isLoading
		} = this.state;

		return (
			<>

				{Boolean(images.length > 0) && (
					<Box mb={4}>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item>
								<Typography variant="h3">Дополнительные изображения</Typography>
							</Grid>
							<Grid item>
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<ButtonGroup
											variant="outlined"
											color="primary"
											disabled={!Boolean(selectedImages.length)}
										>
											<Button onClick={this.multiDelete.bind(this, false)}>Удалить</Button>
										</ButtonGroup>
									</Grid>
									<Grid item>
										<Checkbox
											checked={Boolean(selectedImages.length === images.length)}
											onChange={this.onFullSelectedImages}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Box mb={2}/>

						<SortableListContainer
							items={images}
							classes={classes}
							selectedImages={selectedImages}

							lockAxis="y"

							onSortEnd={this.changeImagesSort}
							onDeleteImage={this.onDeleteImage}
							onSetMainPhoto={this.onSetMainPhoto}
							onHidePhoto={this.onHidePhoto}
							onChangeCaptionImage={this.onChangeCaptionImage}
							onChangeSelectedImages={this.onChangeSelectedImages}
						/>
					</Box>
				)}

				<Dropzone
					accept='image/jpeg,image/jpg,image/png'
					onDrop={this.onAddNewImages}
				>
					{({getRootProps, getInputProps}) => (
						<Box
							{...getRootProps({className: "dropzone"})}
							sx={{
								cursor: 'pointer',
							}}
							borderRadius={2}
							height={107}
							border='1px solid rgba(0, 87, 255, 0.5)'
							bgcolor="rgba(0, 87, 255, 0.05);"
							p={2}
						>
							<Box
								height={107}
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<input {...getInputProps()} />
								<Grid container direction="column" alignItems="center" justifyContent="center">
									<Grid item>
										<Box display="flex" alignItems="center">
											<BurstMode sx={{fill: "#0057FF"}}/>
											<Typography sx={{
												fontSize: 16,
												fontWeight: 500,
												textTransform: 'uppercase',
												letterSpacing: "0.1em",
												color: "#0057FF",
												marginLeft: '8px',
											}} variant="h5">Перетащите или выберите изображения</Typography>
										</Box>
									</Grid>
									<Grid item>
										<FormHelperText sx={{fontSize: '16px'}}>
											Максимальный вес файла 5мб, максимальный размер по ширине или высоте 3000px, разрешенные типы файлов: png, jpg, jpeg
										</FormHelperText>
									</Grid>
								</Grid>
							</Box>
						</Box>
					)}
				</Dropzone>


				<Backdrop open={isLoading}>
					<CircularProgress color="primary"/>
				</Backdrop>

				<DialogConfirmation
					ref={this.refDialogConfirmation}
				/>
			</>
		);
	}
}

const SortableListContainer = SortableContainer(
	({
		 items,
		 classes,
		 selectedImages,

		 onDeleteImage,
		 onSetMainPhoto,
		 onHidePhoto,
		 onChangeCaptionImage,
		 onChangeSelectedImages
	 }) => (
		<Grid container spacing={2}>
			{items.map((image, index) => (
				<SortableItem
					key={`row-images-id-${ image.imageId }-${index}`}
					index={index}
					image={image}
					classes={classes}
					indexFiles={index}
					selectedImages={selectedImages}

					onDeleteImage={onDeleteImage}
					onSetMainPhoto={onSetMainPhoto}
					onHidePhoto={onHidePhoto}
					onChangeCaptionImage={onChangeCaptionImage}
					onChangeSelectedImages={onChangeSelectedImages}
				/>
			))}
		</Grid>
	))
const SortableItem = SortableElement(({ image, classes, indexFiles, onChangeCaptionImage, onDeleteImage, onSetMainPhoto, onHidePhoto, selectedImages,  onChangeSelectedImages}) => (
	<Grid item container spacing={1}>
		<Grid item>
			<DragHandle
				classes={classes}
			/>
		</Grid>
		<Grid item>
			<Tooltip
				title={<React.Fragment>
					<div style={{borderRadius: "4px", pointerEvents: "none"}}>
						<img
							width={256}
							height={256}
							style={{
								objectFit: 'contain',
								borderRadius: "4px",
								maxWidth: "100%"
							}}
							src={`${process.env.REACT_APP_HOST_API}api/storage/image/${image.imageId}_w-500_h-500.webp`}
							alt=""/>
					</div>
				</React.Fragment>}

				arrow
			>
				<div style={{borderRadius: "4px"}}>
					<img
						width={96}
						height={96}
						style={{
							objectFit: 'contain',
							borderRadius: "4px"
						}}
						src={`${process.env.REACT_APP_HOST_API}api/storage/image/${image.imageId}_w-500_h-500_fit-contain.webp`}
						alt=""/>
				</div>
			</Tooltip>
		</Grid>
		<Grid item alignSelf='flex-start' sx={{flex: 1}}>
			<TextField
				multiline
				rows={4}
				value={image.caption}
				placeholder="Описание"
				fullWidth
				sx={{height: "auto!important", "& .MuiOutlinedInput-root": {height: "auto"}}}
				onChange={(event) => onChangeCaptionImage(event, indexFiles)}
			/>
		</Grid>
		<Grid item ml='auto'>
			<Grid container spacing={2}>
				<Grid item>
					<Tooltip title="Сделать фото основным" arrow>
						<IconButton
							sx={{
								width: 96,
								height: 96,
								background: "rgba(67, 160, 71, 0.05)",
								borderRadius: 1,
								border: "1px solid rgba(67, 160, 71, 0.7)"
							}}
							onClick={() => onSetMainPhoto(indexFiles)}
						>
							<PhotoIcon color="success" sx={{pointerEvents: "none"}}/>
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item>
					<Tooltip title="Скрыть фото" arrow>
						<IconButton
							sx={{
								width: 96,
								height: 96,
								background: "rgba(0, 87, 255, 0.05)",
								borderRadius: 1,
								border: "1px solid rgba(0, 87, 255, 0.7)"
							}}
							onClick={() => onHidePhoto(indexFiles)}
						>
							<HideImageIcon color="primary" sx={{pointerEvents: "none"}}/>
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item>
					<Tooltip title="Удалить фото" arrow>
						<IconButton
							sx={{
								width: 96,
								height: 96,
								background: "rgba(229, 57, 53, 0.05)",
								borderRadius: 1,
								border: "1px solid rgba(229, 57, 53, 0.7)"
							}}
							onClick={() => onDeleteImage(indexFiles)}
						>
							<DeleteIcon color="error" sx={{pointerEvents: "none"}}/>
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item>
					<Checkbox
						checked={Boolean(selectedImages.includes(indexFiles))}
						onChange={onChangeSelectedImages.bind(this, indexFiles)}
					/>
				</Grid>
			</Grid>
		</Grid>
	</Grid>
))
const DragHandle = SortableHandle(({ classes }) => (
	<Box className={classes.buttonSorted}>
		<DragHandleIcon/>
	</Box>
))

const styles = {
	buttonSorted: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",

		width: 38,
		height: "100%",
		backgroundColor: "rgba(0, 87, 255, 0.05)",
		borderRadius: 4,
		border: "1px solid rgba(0, 87, 255, 0.7)",
		color: "#0057FF",

		"&:hover": {
			backgroundColor: "rgba(0, 87, 255, 0.8)",
			color: "#FCFCFC",
		}
	}
}
DropZoneSecond = withStyles(styles)(DropZoneSecond)

export default DropZoneSecond;
