import React from "react";
import {
	Box,
	Grid,
	Button,
	Tooltip,
	IconButton
} from "@mui/material";
import {
	ArrowBack as ArrowBackIcon,
	Edit as EditIcon,
	Cancel as CancelIcon,
	Email as EmailIcon,
	AccessAlarms as AccessAlarmsIcon,
	Receipt as ReceiptIcon,
	DirectionsCar as DirectionsCarIcon,
	Print as PrintIcon,
	Description as DescriptionIcon
} from "@mui/icons-material";
import {
	useHistory
} from "react-router-dom";

const Controls = (props) => {
	const {
		crmOrderId,
		isCancel,
		orderEdit,
		isExported1c,
		onRouteEditPage
	} = props;
	const history = useHistory();

	const _routeGoBack = () => {
		history.goBack();
	}
	const _cancel = () => {
	}
	const _bill = () => {
	}
	const _print = () => {
	}
	const _excel = () => {
	}
	const _exportToCRM = () => {

	}

	const _tooltipTitleEditButton = () => {
		if (Object.keys(orderEdit || {}).length > 0) {
			return `Заказа уже редактируют: ${ orderEdit?.user?.firstname }`
		}
		if (Boolean(isCancel)) {
			return "Нельзя редактировать отмененный заказ"
		}
		if (!isExported1c) {
			return "Заказ можно отредактировать только после выгрузки в CRM текущей редакции"
		}
		return "Редактировать"
	}

	return (
		<Grid container spacing={1} wrap="wrap">
			<Grid item>
						<Button
							variant="contained"
							color="secondary"
							startIcon={<ArrowBackIcon/>}
							onClick={_routeGoBack}>Вернуться</Button>
			</Grid>
			<Grid item>
				<Tooltip arrow title={_tooltipTitleEditButton()}>
					<Box>
				<Button variant="contained" disabled={!isExported1c || isCancel || Object.keys(orderEdit || {}).length > 0} color="primary" startIcon={<EditIcon/>}
				        onClick={onRouteEditPage}>Редактировать</Button>
					</Box>
				</Tooltip>
			</Grid>
			<Grid item>
				<Tooltip title={Boolean(crmOrderId) ? "Перейти" : "Заказ в процессе отправки в CRM, попробуйте через минуту."} arrow>
					<Box>
						<Button disabled={!crmOrderId} component="a" href={`http://crm.mia-sofia.ru/order/view/?id=${crmOrderId}`} target="_blank" variant="contained" color="primary" startIcon={<DescriptionIcon/>} onClick={_exportToCRM}>
							Выгрузить/открыть в CRM
						</Button>
					</Box>
				</Tooltip>
			</Grid>
		</Grid>
	)
}

export default Controls
