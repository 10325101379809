import React, {useEffect, useRef, useState} from 'react';
import {
	Box,
	Checkbox,
	Collapse,
	FormControlLabel,
	IconButton,
	Pagination,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField, Tooltip,
	Typography
} from "@mui/material";
import {KeyboardArrowDown as ArrowDownIcon, KeyboardArrowUp as ArrowUpIcon} from '@mui/icons-material';
import {Formik} from 'formik';
import agent from "../../../../../agent/agent";
import {makeStyles} from "@mui/styles";
import {Notification, NotificationTypes} from "../../../../../common/Notification";
import {TableCharacteristicChange, TableRowTaggablePlugin} from "../../../../../components";
import {IMaskInput} from "react-imask";
import * as Yup from "yup";
import CharacteristicGroupComponent from "./CharacteristicGroupComponent";


const TableComponent = (props) => {
	const {
		products,
		filter,
		pagination,

		isLoading,

		onChangePagination
	} = props;

	const classes = useStyles();

	const handleChangePage = (event, page) => {
		let newFilter = {...filter};
		newFilter.page = page;

		onChangePagination(newFilter, true);
	}
	return (
		<>
			<Box px={2} py={4} mt={2} bgcolor="white" borderRadius={2}>
				<TableContainer sx={{maxHeight: "calc(100vh - 152px)"}}>
					<Table className={classes.table} >
						<TableHead>
							<TableRow>
								<TableCell/>
								<TableCell>ID</TableCell>
								<TableCell>Название</TableCell>
								<TableCell>Артикул</TableCell>
								<TableCell>Длина</TableCell>
								<TableCell>Ширина</TableCell>
								<TableCell>Высота</TableCell>
								<TableCell>Глубина</TableCell>
								<TableCell>Статус</TableCell>
								<TableCell>Артикул поставщика</TableCell>
								<TableCell>Цена со скидкой</TableCell>
								<TableCell>Базовая цена</TableCell>
								<TableCell>Цена в евро</TableCell>
								<TableCell>Фабрика</TableCell>
								<TableCell>Точ. логист. данных</TableCell>
								<TableCell>Коллекция</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Boolean(isLoading) ? (<>
									{[0, 1, 2, 3, 4, 5].map((item) => (
											<TableRow key={`row-item-load-${item}`}>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
												<TableCell><Skeleton/></TableCell>
											</TableRow>
										)
									)}
								</>
							) : (
								<>
									{products.length > 0
										? (
											<>
												{
													products.map(product => (
														<CollapsibleTable
															key={product.id}
															product={product}
														/>
													))
												}
											</>
										)
										: (
											<TableRow>
												<TableCell colSpan={10}>
													<Typography variant="h2" textAlign="center">
														Ничего не найдено
													</Typography>
												</TableCell>
											</TableRow>
										)
									}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>

				<Box mt={2}>
					<Pagination
						page={Number(filter.page)}
						count={pagination.count || 1}

						onChange={handleChangePage}
					/>
				</Box>
			</Box>
		</>
	);
};

let timeout = null;

const CollapsibleTable = React.memo((props) => {
	const {
		product
	} = props;
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(false);
	const [characteristics, setCharacteristics] = useState(null);
	const refFormik = useRef(null);

	React.useEffect(() => {
		(async () => {
			await getCharacteristics();
		})();
	}, [product.id]);

	const getCharacteristics = async () => {
		let expand = [
			'sections',
			'sections.characteristicAssignments',
			'sections.characteristicAssignments.characteristic',
			'sections.characteristicAssignments.characteristic.decodedVariants'
		].join(',')
		// const urlQuery = `/admin/api-shop-characteristic-groups/${product.characteristic_group_id}?expand=${expand}`;
		const urlQuery = `/api/admin/products/${product.id}/characteristics?expand=${expand}`;
		const response = await agent.get(urlQuery).then((res) => {
			let fullCharacteristics = [];
			(res.data || []).map((item) => {
				fullCharacteristics.push(...(item?.characteristics || []));
			})

			return fullCharacteristics
		}).catch(() => {
			return []
		});

		setCharacteristics(response);
	}

	const handleChangePrice = (e) => {
		clearTimeout(timeout);
		const {value, name} = e.target;
		const newForm = refFormik.current.values;
		newForm[name] = value;

		refFormik.current.setValues(newForm);

		if (value.length >= 1) {
			timeout = setTimeout(async () => {
				await _changePrice(name);
			}, 500);
		} else {
			clearTimeout(timeout);
		}
	}
	const handleChangeBoolean = async ({target}, value) => {
		const {name} = target;

		let newForm = refFormik.current.values;
		newForm[name] = value ? 1 : 0;

		refFormik.current.setValues(newForm);

		await _changePrice(name);
	}
	const handleSetIsOpen = () => {
		if (product.extraOptions.length > 0) {
			setIsOpen(!isOpen);
		}
	}
	const _changePrice = async (name) => {
		clearTimeout(timeout);
		const body = {};
		body[name] = refFormik.current.values[name];

		timeout = setTimeout(async () => {
			await agent.put(`/api/admin/products/${product.id}`, body).then((res) => {
				Notification({
					message: `Поле ${getFieldName(name)} успешно изменено`,
					type: NotificationTypes.success,
				})
			}).catch((err) => {
				Notification({
					message: err.response.data[name][0],
					type: NotificationTypes.error,
				})
			});
		}, 500)
	}
	const renderCollections = () => {
		if (product.extraProductSetParents.length > 0) {
			const objectType = {
				api: `api-shop-products`,
			}
			return (
				<TableRowTaggablePlugin
					value={product.extraProductSetParents}
					objectType={objectType}
				/>
			);
		} else {
			return "Нет коллекций"
		}
	}
	const getFieldName = (name) => {
		switch (name) {
			case "price_old":
				return "Старая цена";
			case 'price_new':
				return "Цена";
			case "width":
				return "Ширина";
			case "height":
				return "Высота";
			case "depth":
				return "Глубина";
			case "seats":
				return "Мест";
			case "weight":
				return "Вес";
			case "volume":
				return "Объем";
			case "sku":
				return "Артикул поставщика";
			case "supplier_price":
				return "Цена в евро";
			default:
				return "Неопределенное поле";
		}
	}

	const handleChangePrices = ({target}) => {
    if (!refFormik.current) {
      return
    }

		clearTimeout(timeout);

		const {name, value} = target;
		let values = refFormik.current.values;
		values[name] = value;

		refFormik.current.setValues(values);
		timeout = setTimeout(() => {
			refFormik.current.handleSubmit();
		}, 1000)
	}
	const handleSubmit = async (form) => {
		let formData = {
			price_old: form.price_old
		}

		if (+form.price_special) {
			formData.price_new = form.price_special;
		}
		if (!+form.price_special) {
			formData.price_new = form.price_old;
		}

		const response = await agent.put(`/api/admin/products/${product.id}`, formData).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err?.response}
		});
		if (response.error) {
			Notification({
				type: NotificationTypes.error,
				message: ""
			})

			return
		}

		Notification({
			message: `Цена успешно изменена`,
			type: NotificationTypes.success,
		})
	};

	const handleChangeProduct = ({target}) => {
		clearTimeout(timeout);

		const {name, value} = target;

		let values = refFormik.current.values;
		values[name] = value;

		refFormik.current.setValues(values);

		timeout = setTimeout(async () => {
			await handleSubmitProduct(values);
		}, 1000)
	}
	const handleSubmitProduct = async (values) => {
		let formData = {...values}

		const response = await agent.put(`/api/admin/products/${product.id}`, formData).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err?.response}
		});
		if (response.error) {
			Notification({
				type: NotificationTypes.error,
				message: ""
			})

			return
		}

		Notification({
			message: `Успешно изменено`,
			type: NotificationTypes.success,
		})
	};

	return (
		<>
			<Formik
				innerRef={refFormik}
				initialValues={product}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{(props) => {
					const {
						values,
						errors,
						touched
					} = props;

					return (
						<>
							<TableRow hover key={values.id} style={{
								cursor: !product.extraOptions.length > 0 && "default",
							}} onClick={handleSetIsOpen}>
								{
									product.extraOptions.length > 0
										? (
											<TableCell>
												<IconButton
													aria-label="expand row"
													size="small"
													onClick={handleSetIsOpen}
												>
													{isOpen ? <ArrowUpIcon/> : <ArrowDownIcon/>}
												</IconButton>
											</TableCell>
										)
										: (
											<TableCell/>
										)
								}
								<TableCell>{values.id}</TableCell>
								<TableCell>{values.name}</TableCell>
								<TableCell>{values.code}</TableCell>

								{/* CharacteristicGroupComponent */}
								<TableCell>
									{/* Длина */}
									{Boolean(characteristics !== null) && (
										<CharacteristicGroupComponent
											key={`CharacteristicGroupComponent-45`}
											productId={product.id}
											сharacteristicId={45}
											extraValue={product?.extraValues[45] || null}
											characteristics={characteristics}
										/>
									)}
								</TableCell>
								<TableCell>
									{/* Ширина */}
									{Boolean(characteristics !== null) && (
										<CharacteristicGroupComponent
											key={`CharacteristicGroupComponent-46`}
											productId={product.id}
											сharacteristicId={46}
											extraValue={product?.extraValues[46] || null}
											characteristics={characteristics}
										/>
									)}
								</TableCell>
								<TableCell>
									{/* Ширина */}
									{Boolean(characteristics !== null) && (
										<CharacteristicGroupComponent
											key={`CharacteristicGroupComponent-47`}
											productId={product.id}
											сharacteristicId={47}
											extraValue={product?.extraValues[47] || null}
											characteristics={characteristics}
										/>
									)}
								</TableCell>
								<TableCell>
									{/* Глубина */}
									{Boolean(characteristics !== null) && (
										<CharacteristicGroupComponent
											key={`CharacteristicGroupComponent-48`}
											productId={product.id}
											сharacteristicId={48}
											extraValue={product?.extraValues[48] || null}
											characteristics={characteristics}
										/>
									)}
								</TableCell>
								{/* ---------------------------- */}

								<TableCell>
									<FormControlLabel
										control={<Checkbox/>}
										checked={values.status}
										value={values.status}
										name="status"
										label=""
										labelPlacement="bottom"
										onChange={handleChangeBoolean}
									/>
								</TableCell>
								<TableCell>
									<Tooltip title={values.sku}>
										<TextField
											className={classes.textField}
											size="small"
											value={values.sku}
											name="sku"
											placeholder="Введите"

											onClick={(e) => e.stopPropagation()}
											onChange={handleChangePrice}
										/>
									</Tooltip>
								</TableCell>

								{Boolean(false) && (
									<TableCell>
										<TextField
											className={classes.textField}
											size="small"
											value={values.price_new}
											name="price_new"
											placeholder="0,00"
											InputProps={{
												inputComponent: CustomInputAmount
											}}
											inputProps={{
												scale: 2
											}}

											onClick={(e) => e.stopPropagation()}
											onChange={handleChangePrice}
										/>
									</TableCell>
								)}
								<TableCell>
									<TextField
										className={classes.textField}
										size="small"
										value={String(values.price_special)}
										name="price_special"
										error={Boolean(touched.price_special && errors.price_special)}
										helperText={touched.price_special && errors.price_special}
										placeholder="0,00"
										InputProps={{
											inputComponent: CustomInputAmountMax
										}}
										inputProps={{
											scale: 2
										}}

										onClick={(e) => e.stopPropagation()}
										onChange={handleChangePrices}
									/>
								</TableCell>
								<TableCell>
									<TextField
										className={classes.textField}
										size="small"
										value={String(values.price_old)}
										error={Boolean(touched.price_old && errors.price_old)}
										helperText={touched.price_old && errors.price_old}
										name="price_old"
										placeholder="0,00"
										InputProps={{
											inputComponent: CustomInputAmountMax
										}}
										inputProps={{
											scale: 2
										}}

										onClick={(e) => e.stopPropagation()}
										onChange={handleChangePrices}
									/>
								</TableCell>

								<TableCell>
									<TextField
										className={classes.textField}
										size="small"
										value={values.supplier_price}
										name="supplier_price"
										placeholder="0,00"
										InputProps={{
											inputComponent: CustomInputAmount
										}}
										inputProps={{
											scale: 2
										}}

										onClick={(e) => e.stopPropagation()}
										onChange={handleChangePrice}
									/>
								</TableCell>
								<TableCell>{values.brand}</TableCell>
								<TableCell
									align="center"

									onClick={(event) => event.stopPropagation()}
								>
									<Checkbox
										size="medium"
										checked={!Boolean(values.use_default_packages)}
										onChange={(event, value) => handleChangeProduct({
											target: {
												name: "use_default_packages",
												value: !value
											}
										})}
									/>
								</TableCell>
								<TableCell
									onClick={(event) => event.stopPropagation()}
								>
									{<>
										<Box
											sx={{
												position: "relative",
											}}
										>
											{renderCollections()}
										</Box>
									</>}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={19}>
									<Collapse in={isOpen} timeout="auto" unmountOnExit>
										<Table className={classes.subTable} size="small">
											<TableHead>
												<TableRow>
													<TableCell>Наименование настройки</TableCell>
													<TableCell>Наименование</TableCell>
													<TableCell>Артикул</TableCell>
													<TableCell>Вес</TableCell>
													<TableCell>Высота</TableCell>
													<TableCell>Ширина</TableCell>
													<TableCell>Глубина</TableCell>
													<TableCell>Объем</TableCell>
													<TableCell>Кол-во мест</TableCell>
													<TableCell align="center">Как товар</TableCell>
													<TableCell align="center">Статус</TableCell>
													<TableCell>Порядок</TableCell>
													<TableCell>Цена со скидкой</TableCell>
													<TableCell>Базовая цена</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{(product?.extraOptions || []).map((extraOpt, extraOptIndex) => (
													(extraOpt?.variants || []).map((opt, optIndex) => (
														<SubTable
															classes={classes}
															productId={product.id}
															extraOption={extraOpt}
															option={opt}

															refFormik={refFormik}
															errors={errors}
															optIndex={optIndex}
															extraOptIndex={extraOptIndex}
														/>
													))
												))}
											</TableBody>
										</Table>
									</Collapse>
								</TableCell>
							</TableRow>
						</>
					);
				}}
			</Formik>
		</>
	);
});

let timeOutSubTable = null;
const SubTable = React.memo((props) => {
	const {
		classes,
		productId,
		extraOption,
		option
	} = props;
	const refFormik = useRef(null);
	const handleSubmit = () => {
	};

	const handleChange = (e) => {
    if (!refFormik.current) {
      return
    }

		clearTimeout(timeOutSubTable);

		refFormik.current.handleSubmit();

		const {value, name} = e.target;
		const newForm = refFormik.current.values;
		newForm[name] = String(value);

		refFormik.current.setValues(newForm);

		// if (value.length < 1) {
		// 	return
		// }

		timeOutSubTable = setTimeout(async () => {
			if (Object.keys(refFormik.current.errors || {}).length > 0) {
				return
			}
			await _changePrice(name);
		}, 1500);
	}
	const _changePrice = async (name) => {
		const body = {};
		body[name] = refFormik.current.values[name];

		if (name === "price_value" && refFormik.current.values[name] === "") {
			body.price_value = refFormik.current.values?.price_old_value;
			body.price_old_value = refFormik.current.values?.price_old_value;
		}
    if (name === "price_old_value" && refFormik.current.values.price_value === "") {
      body.price_value = refFormik.current.values?.price_old_value;
      body.price_old_value = refFormik.current.values?.price_old_value;
    }

		await agent.put(`/api/admin/products/${productId}/options/${option.id}`, body)
			.then((res) => {
				Notification({
					message: `Изменения в поле '${getNameField(name)}' внесены успешно`,
					type: NotificationTypes.success,
				})
			}).catch((err) => {
				Notification({
					message: err.response?.data?.[name]?.[0],
					type: NotificationTypes.error,
				})
			});
	}
	const handleChangeBoolean = async ({target}, value) => {
		const {name} = target;

		let newForm = refFormik.current.values;
		newForm[name] = value ? 1 : 0;

		refFormik.current.setValues(newForm);

		await _changePrice(name);
	}
	const getNameField = (name) => {
		switch (name) {
			case "price_value":
				return "Цена со скидкой";
			case "price_old_value":
				return "Базовая цена";
			case "supplier_price":
				return "Цена в евро";
			case "weight":
				return "Вес"
			case "height":
				return "Высота";
			case "width":
				return "Ширина";
			case "depth":
				return "Глубина";
			case "volume":
				return "Объем";
			case "seats":
				return "Кол-во мест";
			case "is_product":
				return "Как товар";
			case "sort":
				return "Порядок";
			default:
				return "Неопределенное поле";
		}
	}

	return (
		<Formik
			innerRef={refFormik}
			initialValues={option}
			validationSchema={validationSchemaOptions}
			onSubmit={handleSubmit}
		>
			{(props) => {
				const {
					values,
					errors,
					touched
				} = props;

				return (
					<>
						<TableRow hover key={values.id}>
							<TableCell>{extraOption.name}</TableCell>
							<TableCell>{values.name}</TableCell>
							<TableCell>{values.sku}</TableCell>
							<TableCell>
								<TextField
									className={classes.textField}
									size="small"
									value={values.weight}
									name="weight"
									placeholder="0,00"
									InputProps={{
										inputComponent: CustomInputAmount
									}}
									inputProps={{
										scale: 2
									}}

									onClick={(e) => e.stopPropagation()}
									onChange={handleChange}
								/>
							</TableCell>
							<TableCell>
								<TextField
									className={classes.textField}
									size="small"
									value={values.size_height}
									name="size_height"
									placeholder="0,00"
									InputProps={{
										inputComponent: CustomInputAmount
									}}
									inputProps={{
										scale: 2
									}}

									onClick={(e) => e.stopPropagation()}
									onChange={handleChange}
								/>
							</TableCell>
							<TableCell>
								<TextField
									className={classes.textField}
									size="small"
									value={values.size_width}
									name="size_width"
									placeholder="0,00"
									InputProps={{
										inputComponent: CustomInputAmount
									}}
									inputProps={{
										scale: 2
									}}

									onClick={(e) => e.stopPropagation()}
									onChange={handleChange}
								/>
							</TableCell>
							<TableCell>
								<TextField
									className={classes.textField}
									size="small"
									value={values.size_depth}
									name="size_depth"
									placeholder="0,00"
									InputProps={{
										inputComponent: CustomInputAmount
									}}
									inputProps={{
										scale: 2
									}}

									onClick={(e) => e.stopPropagation()}
									onChange={handleChange}
								/>
							</TableCell>
							<TableCell>
								<TextField
									className={classes.textField}
									size="small"
									value={values.volume}
									name="volume"
									placeholder="0,00"
									InputProps={{
										inputComponent: CustomInputAmount
									}}
									inputProps={{
										scale: 2
									}}

									onClick={(e) => e.stopPropagation()}
									onChange={handleChange}
								/>
							</TableCell>
							<TableCell>
								<TextField
									className={classes.textField}
									size="small"
									value={values.seats}
									name="seats"
									placeholder="0"
									InputProps={{
										inputComponent: CustomInputAmount
									}}

									onClick={(e) => e.stopPropagation()}
									onChange={handleChange}
								/>
							</TableCell>
							<TableCell align="center">
								<FormControlLabel
									control={<Checkbox/>}
									checked={values.is_product}
									value={values.is_product}
									name="is_product"
									label=""
									labelPlacement="bottom"
									onChange={handleChangeBoolean}
								/>
							</TableCell>
							<TableCell align="center">
								<FormControlLabel
									control={<Checkbox/>}
									checked={values.status}
									value={values.status}
									name="status"
									label=""
									labelPlacement="bottom"
									onChange={handleChangeBoolean}
								/>
							</TableCell>
							<TableCell>
								<TextField
									className={classes.textField}
									size="small"
									value={values.sort}
									name="sort"
									placeholder="0"
									InputProps={{
										inputComponent: CustomInputAmount
									}}

									onClick={(e) => e.stopPropagation()}
									onChange={handleChange}
								/>
							</TableCell>
							<TableCell>
								<TextField
									className={classes.textField}
									size="small"
									value={String(values.price_value)}
                  disabled={Boolean(option?.option_preset_disable_sync_prices)}
									name="price_value"
									placeholder="0,00"
									InputProps={{
										inputComponent: CustomInputAmountMax
									}}
									inputProps={{
										scale: 2
									}}
									error={Boolean(touched.price_value && errors.price_value)}
									helperText={touched.price_value && errors.price_value}

									onClick={(e) => e.stopPropagation()}
									onChange={handleChange}
								/>
							</TableCell>
							<TableCell>
								<TextField
									className={classes.textField}
									size="small"
									value={String(values.price_old_value)}
                  disabled={Boolean(option?.option_preset_disable_sync_prices)}
									name="price_old_value"
									placeholder="0,00"
									InputProps={{
										inputComponent: CustomInputAmountMax
									}}
									inputProps={{
										scale: 2
									}}
									error={Boolean(touched.price_old_value && errors.price_old_value)}
									helperText={touched.price_old_value && errors.price_old_value}

									onClick={(e) => e.stopPropagation()}
									onChange={handleChange}
								/>
							</TableCell>
						</TableRow>
					</>
				);
			}}
		</Formik>
	);
});

const CustomInputAmount = ({inputRef, ...rest}) => (
	<IMaskInput
		ref={inputRef}
		{...rest}

		mask={Number}
		thousandsSeparator=""
		radix="."
		mapToRadix={[',']}
		scale={rest?.scale || 0}
		unmask={true}
	/>
)
const CustomInputAmountMax = ({inputRef, ...rest}) => {
  const onComplete = (value, mask) => {
    rest.onChange({
      target: {
        name: rest.name,
        value
      }
    })
  }

  return (
    <IMaskInput
      ref={inputRef}
      {...rest}

      mask={Number}
      thousandsSeparator=""
      radix="."
      mapToRadix={[',']}
      scale={rest?.scale || 0}
      unmask={true}

      onAccept={onComplete}
      onChange={null}
    />
  )
}

const useStyles = makeStyles({
	table: {
		"& .MuiTableHead-root": {
			position: "sticky",
			top: 0,
			zIndex: 100,
		},
		'& .MuiTableBody-root': {
			'& .MuiTableRow-root': {
				"&:nth-child(2n)": {
					background: "none",
				},
				"&:nth-child(4n - 1)": {
					backgroundColor: "#F4F4F4",
				}
			},
			'& .MuiTableCell-body': {
				padding: "0 4px",
			}
		}
	},
	subTable: {
		margin: "5px 0",
		borderCollapse: "collapse",

		"& .MuiTableHead-root .MuiTableCell-root": {
			padding: "4px 6px",
			fontSize: 12,
			lineHeight: "14px",
			fontWeight: "500",
			backgroundColor: "#3855EC",
		},
		"& .MuiTableBody-root .MuiTableCell-root": {
			padding: "0 6px",
			fontSize: 14,
			lineHeight: "14px",
			fontWeight: "500"
		},
		"& .MuiTableBody-root .MuiTableRow-root": {
			backgroundColor: "white!important",
			borderBottom: "1px solid #E6E6E6",
			cursor: "default",
		},
	},

	textField: {
		"& .MuiOutlinedInput-root": {
			height: "auto"
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			padding: "2px 6px"
		},
	}
})

const validationSchema = Yup.object().shape({
	price_old: Yup.number().min(1, "Базовая стоимость не может пустой или 0").nullable().required('Заполните поле'),
	price_special: Yup
		.number()
		.nullable()
		.when(['price_old'], (price_old, schame) => {
			return schame.max(price_old, "\"Цена со скидкой\" не может быть больше \"Базовой стоимости\"")
		}),
	extraOptions: Yup.array().of(Yup.object().shape({
		variants: Yup.array().of(Yup.object().shape({
			name: Yup.string().required('Заполните поле'),
			price_old_value: Yup.number().required('Заполните поле'),
			price_value: Yup
				.number()
				.when(['price_old_value'], (price_old_value, schame) => {
					return schame.max(Number.parseFloat(price_old_value || 0), "\"Цена со скидкой\" не может быть больше \"Базовой стоимости\"")
				})
		}))
	}))
});
const validationSchemaOptions = Yup.object().shape({
	price_old_value: Yup.number().required('Заполните поле'),
	price_value: Yup
		.number()
		.when(['price_old_value'], (price_old_value, schame) => {
			return schame.max(Number.parseFloat(price_old_value || 0), "\"Цена со скидкой\" не может быть больше \"Базовой стоимости\"")
		})
});

export default React.memo(TableComponent);
