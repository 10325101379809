import React, {Component} from "react";
import {Backdrop, Box, Button, CircularProgress, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {KeyboardBackspace as KeyboardBackspaceIcon} from "@mui/icons-material";
import {
	AdditionalCategoriesForm,
	AdditionallyForm,
	CategoryManufacturerForm,
	Characteristics,
	ControlProductEdit,
	ControlsDeleteProduct,
	FilesForm,
	HeaderProductEdit,
	ImageAnnotations,
	ImagesForm,
	MainForm,
	MarketplacesForm,
	Navigations,
	OptionsForm,
	PackageForm,
	ProductsCollection,
	RelatedSimilarProductsForm,
	SeoForm,
	StoresForm,
	VideosForm
} from "./components";
import agent from "../../../agent/agent";
import urls from "../../../variables/urls";
import {getImageId} from "../../../common/FilesUpload";
import {DialogConfirmation} from "../../../components";
import {Notification, NotificationTypes} from "../../../common/Notification";
import {uploadImages} from "../../../helper/upload-images";
import {filesGetFromUpload, filesUpload} from "../../../helper/files-upload";

const navigationList = [
	{tab: "main", label: "Основные параметры", type: "all"},
	{tab: "characteristics", label: "Характеристики", type: "all"},
	{tab: "seo", label: "Мета информация", type: "all"},
	{tab: "images", label: "Изображения", type: "all"},
	{tab: "videos", label: "Видео", type: "all"},
	{tab: "files", label: "Файлы", type: "all"},
	{tab: "options", label: "Настраиваемыe варианты", ref: "refOptionsForm", type: "product"},
	{tab: "products-collection", label: "Товары и коллекция", type: "collection"},
	{tab: "category-manufacturer", label: "Категория", type: "all"},
	{tab: "related-similar-products", label: "Связанные товары", type: "all"},
	// {tab: "marketplaces", label: "Маркетплейсы", type: "all"},
	{tab: "stores", label: "Магазины", type: "all"},
	{tab: "package", label: "Упаковка", type: "product"},
	{tab: "additionally", label: "Дополнительно", type: "all"},
	{tab: "image-annotations", label: "Метки на фото", type: "collection"},
];

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

class ProductEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			brandId: [],
			categoryId: [],
			extraCategories: [],
			sberbankTaxType: [],
			shopCharacteristicGroups: [],
			characteristicGroupIds: [],
			extraWebsites: [],

			searchTags: [],
			searchTagValue: '',
			searchLoading: false,

			imageDots: [],

			initProductCharacteristics: [],

			product: {},
			editError: {},

			initExtraValues: {},

			productId: props.match.params.id,
			activeTab: "main",

			currentHistoryLength: 0,

			isLoad: true,
			isShowBackdrop: false,
			isCreatedProduct: false,
			isError: false,
			isChangedExtraOptions: false
		};

		this.timeOutSearch = null;
		this.timeOutEventEdit = null;

		this.refMainForm = React.createRef();
		this.refVideos = React.createRef();
		this.refSeoForm = React.createRef();
		this.refMarketplacesForm = React.createRef();
		this.refPackageForm = React.createRef();
		this.refAdditionallyForm = React.createRef();
		this.refImagesForm = React.createRef();
		this.refStoresForm = React.createRef();
		this.refOptionsForm = React.createRef();
		this.refCharacteristicsForm = React.createRef();
		this.refCategoryManufacturerForm = React.createRef();
		this.refAdditionalCategoriesForm = React.createRef();
		this.refRelatedSimilarProductsForm = React.createRef();
		this.refDescriptionCharacteristicsForm = React.createRef();
		this.refFilesForm = React.createRef();
		this.refTagsBenefitsForm = React.createRef();
		this.refAdditionallyForm = React.createRef();
		this.refProductsCollection = React.createRef();
		this.refImageAnnotations = React.createRef();
		this.refDialogConfirmation = React.createRef();
	}

	componentDidMount = async () => {
		this.setState({currentHistoryLength: this.props.history.length})

		await this.parseHashPage();
		await this.getServices();
		await this.getProduct();
		await this.eventEditProductStart();
	}
	componentWillUnmount = async () => {
		await this.eventEditProductEnd();
	}

	// Установка / снятие блокироваки редактирования товара
	setLockProduct = async () => {}

	// Логика работы с продуктом
	getServices = async () => {
		const data = await agent.get(urls.getServices).then((res) => {
			return res.data.service
		}).catch((err) => {
			return {}
		});
		const characteristicGroupIds = await agent.get(`/admin/api-shop-characteristic-groups/list?ngrestCallType=list&characteristicsCount&fields=id,name,is_for_product_set`).then((res) => {
			return (res.data || [])
				.map((t) => {
					return {
						label: t.name,
						value: t.id,
						is_for_product_set: t.is_for_product_set
					}
				})
		}).catch(() => {
			return []
		})

		const brandId = data?.brand_id?.selectdata || [];
		const categoryId = data?.category_id?.selectdata || [];
		const extraCategories = data?.extraCategories?.relationdata?.items || [];
		const sberbankTaxType = data?.sberbank_tax_type?.selectdata || [];

		this.setState({
			brandId,
			categoryId,
			extraCategories,
			sberbankTaxType,
			characteristicGroupIds
		})
	}

	//Логика поиска тегов
	onChangeSearch = (e) => {
		clearTimeout(this.timeOutSearch);
		if (e.target.value.length >= 2) {
			this.timeOutSearch = setTimeout(() => {
				const searchValue = e.target.value;
				this.setState({searchTags: [], searchTagValue: searchValue, searchLoading: true});
				this.timeOutSearch = setTimeout(async () => {
					if (this.state.searchTagValue.length >= 2) {
						await this.getSearchTags(this.state.searchTagValue);
					} else {
						this.setState({searchTags: []});
					}
				}, 1000);
			}, 100);
		} else {
			this.setState({searchTags: [], searchLoading: false});
		}
	}

	getSearchTags = async (search) => {
		if (!search) {
			return
		}
		const data = await agent.get(`/admin/api-shop-tags/search?fields=id,name&query=${search}`)
			.then((res) => res.data)
			.catch((err) => {
				return {}
			});

		this.setState({searchTags: data, searchLoading: false});
	}

	clearSearchTagValue = () => {
		this.setState({searchTags: [], searchTagValue: "", searchLoading: false});
	}

	// Логика по продукта
	getProduct = async () => {
		const expand = 'expand=banner_images,extraWebsites,exchange_hash,is_product_set,extraOptionPresets,extraProductSetParents,metaTitle,metaKeywords,metaDescription,extraValues,file_id,extraCategories,extraTags,extraRelated,extraSame,extraStores,extraOptions,extraOptions.sort,extraPriceGroup';
		const fields = 'fields=supplier_price,banner_images,extraWebsites,exchange_hash,use_default_packages,is_product_set,extraOptionPresets,extraProductSetParents,id,country,barcode,created_at,updated_at,reviews_rating,reviews_count,short_description,characteristic,characteristic_group_id,slug,video_link,advantages,unit,count_step,metaTitle,metaKeywords,metaDescription,extraValues,category_id,brand_id,code,name,description,price_old,price_new,rating,main_photo_id, main_photo_caption,file_id,files,images,status,export_yml,export_avito,export_2gis,export_yula,weight,height,width,depth,volume,seats,quantity,extraCategories,extraTags,extraRelated,extraSame,extraStores,extraOptions,extraOptions.sort,is_special,is_hit,is_new,sku,count_in_pack,sort,external_id,market_product_name,market_sales_notes,market_delivery_options,extraPriceGroup,vat,sberbank_tax_type, product_set_image_dots,extraOptions.optionVariant.size_width,extraOptions.optionVariant.size_height,extraOptions.optionVariant.size_depth,extraOptions.optionVariant.size_length,packages,extraOptions.optionVariant.price_old_value,extraOptions.optionVariant.option_preset_disable_sync_prices,extraOptions.optionVariant.option_preset_disable_sync_status';
		const ngrestCallType = "ngrestCallType=update";

		const search = [ngrestCallType, fields, expand].join('&')
		let product = await agent.get(`${urls.getProduct}/${this.state.productId}?${search}`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		});
		if (product.error) {
			this.setState({
				isError: true,
			})

			return
		}

		if (!product.price_old) {
			product.price_old = product.price_new;
		}
		if (product.price_new !== product.price_old) {
			product.price_special = product.price_new;
		}
		if (product.product_set_image_dots || true) {
			if (Array.isArray(product.product_set_image_dots) && !product.product_set_image_dots?.[0]?.website_id) {
				const initasd = product.product_set_image_dots
					.map(dot => {
						return {
							...dot,
							id: dot.product_id,
						};
					})
					.filter((dot) => {
						return Boolean(dot?.left !== null && dot?.top !== null)
					})
				this.setState({
					imageDots: [
						{website_id: 1, dots: initasd || []},
						{website_id: 2, dots: initasd || []},
						{website_id: 3, dots: initasd || []},
					]
				})
			}
			else if (product.product_set_image_dots === null) {
				this.setState({
					imageDots: [
						{website_id: 1, dots: []},
						{website_id: 2, dots: []},
						{website_id: 3, dots: []},
					]
				})
			}
			else if (!product.product_set_image_dots?.[0]?.website_id) {
				this.setState({
					imageDots: [
						{website_id: 1, dots: product.product_set_image_dots?.[1] || []},
						{website_id: 2, dots: product.product_set_image_dots?.[2] || []},
						{website_id: 3, dots: product.product_set_image_dots?.[3] || []},
					]
				})
			}
			else {
				this.setState({
					imageDots: product.product_set_image_dots
				})
			}
		}
		if ((product.extraOptions || []).length > 0) {
			product.extraOptions = product.extraOptions.map((item) => {
				if ((item?.variants || []).length > 0) {
					item.variants = item.variants.map((variant) => {
						if (!variant.price_old_value) {
							variant.price_old_value = variant.price_value;
						}
						if (variant.price_old_value === variant.price_value) {
							variant.price_value = ""
						}

						return variant
					})
				}

				return {
					...item,
					sort: item.sort || 1
				}
			})
		}
		if ((product.files || []).length > 0) {
			product.files = await Promise.all(product.files.map(async (fileId) => {
				return filesGetFromUpload({ fileId: fileId })
			}))
		}

		product.extraWebsites = (product.extraWebsites || []).map((t) => t?.value || t);

		// banner_images
		product.bannerType = "";
		product.bannerImageId = (product?.banner_images || [])?.[0]?.imageId || null;

		await this.setState({
			product: product,
			extraWebsites: product.extraWebsites || [],
			initExtraValues: JSON.parse(JSON.stringify({...(product.extraValues || {})})),
			isLoad: false
		})

		await this.getCharacteristicGroup();
	}
	editProduct = async (isGoBack = false) => {
		const isValid = await this._isValidAllForm();
		if (!isValid) {
			Notification({
				message: "Ошибка валидации данных",
				type: NotificationTypes.error
			})

			return
		}

		this.setState({isShowBackdrop: true})

		const formData = await this._getFormDataEdit();
		if (typeof formData.extraValues === "object" && Object.keys(formData.extraValues || {}).length <= 0) {
			this.setState({isShowBackdrop: false});
			Notification({
				message: "Вы пытаетесь сохранить пустые хар-тики",
				type: NotificationTypes.error,
			})
			return
		};
		const response = await agent.put(`${urls.editProduct}/${this.state.productId}`, formData).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (!!response.error) {
			this.setState({isShowBackdrop: false})

			let errorMessage = ""
			if (Array.isArray(response.error?.data)) {
				errorMessage = (response.error?.data || [])
					.map((err) => {
						return err.message || ""
					})
					.join("<br/>")
			}
			if (!errorMessage) {
				errorMessage = response.error?.data?.message || "Ошибка сервера"
			}

			Notification({
				message: errorMessage || "Ошибка сервера",
				type: NotificationTypes.error
			})

			return
		}

		Notification({
			message: "Товар успешно изменен",
			type: NotificationTypes.success
		})

		this.setState({isShowBackdrop: false})

		if (!!isGoBack) {
			this._routeProductsPage();
		} else {
			window.location.reload();
		}
	}
	createProduct = async () => {
		this.setState({isShowBackdrop: true})

		const formData = await this._getFormDataEdit();
		const response = await agent.post(`${urls.editProduct}`, formData).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (!!response.error) {
			this.setState({isShowBackdrop: false})

			let errorMessage = ""
			if (Array.isArray(response.error?.data)) {
				errorMessage = (response.error?.data || [])
					.map((err) => {
						return err.message || ""
					})
					.join("<br/>")
			}
			if (!errorMessage) {
				errorMessage = response.error?.data?.message || "Ошибка сервера"
			}

			Notification({
				message: errorMessage || "Ошибка сервера",
				type: NotificationTypes.error
			})

			return
		}

		Notification({
			message: "Товар успешно создан",
			type: NotificationTypes.success
		})

		this.setState({isShowBackdrop: false})
	}
	deleteProducts = async (isConfirm) => {

		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы действительно хотите удалить товар '${this.state.product.name}'?`,
				acceptButtonTitle: "Да, удалить",
				cancelButtonTitle: "Отменить",
				acceptButtonAction: this.deleteProducts.bind(this, true),
			})

			return
		}

		const productId = this.state.productId;
		const response = await agent.delete(`${urls.getProduct}/${productId}`)
			.then(res => res.data)
			.catch(err => {
				return {error: err.response}
			})
		if (response.error) {
			Notification({
				message: "Ошибка удаления товара",
				type: NotificationTypes.error
			});

			return
		}

		Notification({
			message: `Товар '${this.state.product.name}' успешно удален`,
			type: NotificationTypes.success,
		})

		this._routeProductsPage();
	};
	duplicateProduct = async (isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: `Вы действительно хотите дублировать товар '${this.state.product.name}'?`,
				acceptButtonTitle: "Да, дублировать",
				cancelButtonTitle: "Отменить",
				acceptButtonAction: this.duplicateProduct.bind(this, true),
			})

			return
		}

		const response = await agent.post(`api/admin/products/duplicate/${this.state.productId}`)
			.then(res => res.data)
			.catch(err => {
				return {error: err}
			})

		if (response.error) {
			this.setState({isOpenBackdrop: false})

			Notification({
				message: "Возникла ошибка дублирования товара",
				type: NotificationTypes.error
			})

			return
		}

		Notification({
			message: "Товар успешно дублирован",
			type: NotificationTypes.success
		});

		await this._routeDuplicateProductEditPage(response.id);
	}

	// Логика редактирование шаблона опций
	onChangeExtraOptionPresets = async (values) => {
		this.setState({ isShowBackdrop: true })

		let form = { ...this.state.product };
		form.extraOptionPresets = values;

		const response = await agent.put(`${urls.editProduct}/${this.state.productId}`, form).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (!!response.error) {
			this.setState({isShowBackdrop: false})

			let errorMessage = ""
			if (Array.isArray(response.error?.data)) {
				errorMessage = (response.error?.data || [])
					.map((err) => {
						return err.message || ""
					})
					.join("<br/>")
			}
			if (!errorMessage) {
				errorMessage = response.error?.data?.message || "Ошибка сервера"
			}

			Notification({
				message: errorMessage || "Ошибка сервера",
				type: NotificationTypes.error
			})

			return
		}

		window.location.reload();
	}

	// Логика работы с характесристиками
	getCharacteristicGroup = async () => {
		const {product} = this.state;

		if (!product.characteristic_group_id) {
			Notification({
				type: NotificationTypes.warning,
				message: "У товара не заполнен \"Тип товара\""
			})

			return
		}
		let expand = [
			'sections',
			'sections.characteristicAssignments',
			'sections.characteristicAssignments.characteristic',
			// 'sections.characteristicAssignments.characteristic.decodedVariants'
		].join(',')
		const urlQuery = `/admin/api-shop-characteristic-groups/${product.characteristic_group_id}?expand=${expand}`

		const response = await agent.get(urlQuery).then((res) => {
			return res.data?.sections || []
		}).catch(() => {
			return []
		})

		this.setState({
			shopCharacteristicGroups: response
		})
	}

	// Общаяя логика страницы
	changeProductCharacteristicGroup = async (product) => {
		this.refCharacteristicsForm.current.setValues(product);
		await this.setState({product: product});
		await this.getCharacteristicGroup();
	}
	changeProductExtraWebsites = async (product) => {
		this.setState({
			extraWebsites: product?.extraWebsites || []
		});
	}

	// Логика работы с хешем страницы
	onChangeActiveTab = async (activeTab) => {
		await this.setState({activeTab});
		window.history.replaceState(null, null, `#${activeTab}`)
	}
	parseHashPage = () => {
		let initHash = window.location.hash;
		if (!initHash) {
			return
		}

		initHash = initHash.replaceAll("#", "");
		if (!navigationList.find((t) => t.tab === initHash)) {
			window.location.hash = ""

			return null
		}

		this.setState({
			activeTab: initHash
		})
	}

	//Логика работы с метками на изображении
	updateImageDots = (imageDots) => {
		this.setState({ imageDots });
	}

	// Логика работы страницы
	_isValidAllForm = async () => {
		const refsItems = [
			{ref: 'refMainForm', navigation: "main"},
			{ref: 'refImagesForm', navigation: "images"},
			{ref: 'refStoresForm', navigation: "Stores"},
			{ref: 'refCategoryManufacturerForm', navigation: "refCategoryManufacturer"},
			{ref: 'refAdditionalCategoriesForm', navigation: "refAdditionalCategories"},
			{ref: 'refRelatedSimilarProductsForm', navigation: "refRelatedSimilarProducts"}
		];
		let editError = {};

		const functionValidateSection = async (refItem) => {
			await refItem.handleSubmit();
			return await refItem.validateForm().then((res) => {
				return res
			}).catch((err) => {
				return {}
			})
		}
		await Promise.all(refsItems.map(async (item) => {
			const refItem = this?.[item.ref]?.current;
			const errors = await functionValidateSection(refItem);
			if (Object.keys(errors).length > 0) {
				editError[item.navigation] = errors;
			}
		}))

		// Проверка формы "Настраиваемые варианты"
		await this.refOptionsForm?.current.handleSubmit()
		await sleep(1000);
		const refOptionsFormErrors = await this.refOptionsForm?.current.handleGetErrors()
		if (Object.keys(refOptionsFormErrors || {}).length > 0) {
			editError.refOptionsForm = refOptionsFormErrors;
		}

		this.setState({editError});

		return Boolean(Object.keys(editError).length <= 0)
	}
	_getFormDataEdit = async () => {
		let formData = {};

		const mainForm = JSON.parse(JSON.stringify({...(this.refMainForm?.current?.values || {})}));
		formData.name = mainForm?.name || '';
		formData.slug = mainForm?.slug || '';
		formData.code = mainForm?.code || '';
		formData.sku = mainForm?.sku || '';
		formData.quantity = mainForm?.quantity || '';
		formData.unit = mainForm?.unit || '';
		formData.sort = mainForm?.sort || '';
		formData.rating = mainForm?.rating || '';
		formData.vat = mainForm?.vat || '';
		formData.sberbank_tax_type = mainForm?.sberbank_tax_type || '';
		formData.is_hit = mainForm?.is_hit || false;
		formData.is_special = mainForm?.is_special || false;
		formData.is_new = mainForm?.is_new || false;
		formData.is_not_available = mainForm?.is_not_available || false;
		formData.status = Number(mainForm?.status || false);
		formData.brand_id = mainForm?.brand_id || null;
		formData.description = mainForm?.description || null;
		formData.short_description = mainForm?.short_description || null;
		formData.characteristic_group_id = mainForm?.characteristic_group_id || null;
		formData.extraProductSetParents = mainForm?.extraProductSetParents || [];
		formData.extraWebsites = (mainForm.extraWebsites || []);

		if (this.state.product?.is_product_set === 0) {
			formData.price_new = String(mainForm?.price_new || '').replace(/\s/g, '');
			formData.price_old = String(mainForm?.price_old || '').replace(/\s/g, '');
			if (+mainForm.price_special) {
				formData.price_new = mainForm.price_special;
			}
			if (!+mainForm.price_special) {
				formData.price_new = mainForm.price_old;
			}
		}

		// ---------------------------------------------------------------------------------------------------

		const seoForm = JSON.parse(JSON.stringify({...(this.refSeoForm?.current?.values || {})}));
		formData.slug = seoForm?.slug || "";
		formData.metaTitle = seoForm?.metaTitle || "";
		formData.metaKeywords = seoForm?.metaKeywords || "";
		formData.metaDescription = seoForm?.metaDescription || "";

		// ---------------------------------------------------------------------------------------------------

		const marketplacesForm = JSON.parse(JSON.stringify({...(this.refMarketplacesForm?.current?.values || {})}));
		formData.market_product_name = marketplacesForm.market_product_name || "";
		formData.export_yml = marketplacesForm.export_yml || false;
		formData.export_avito = marketplacesForm.export_avito || false;
		formData.export_2gis = marketplacesForm.export_2gis || false;
		formData.export_yula = marketplacesForm.export_yula || false;

		// ---------------------------------------------------------------------------------------------------

		const packageForm = JSON.parse(JSON.stringify({...(this.refPackageForm?.current?.values || {})}));
		formData.volume = packageForm.volume || "";
		formData.weight = packageForm.weight || "";
		formData.seats = packageForm.seats || "";
		formData.use_default_packages = Number(packageForm.use_default_packages);
		formData.packages = (packageForm.packages || []).map((t) => {
			delete t.key
			return t
		});

		// ---------------------------------------------------------------------------------------------------

		const additionallyForm = JSON.parse(JSON.stringify({...(this.refAdditionallyForm?.current?.values || {})}));
		// formData.barcode = additionallyForm.barcode || "";
		// formData.country = additionallyForm.country || "";
		// formData.unit = additionallyForm.unit || "";

		// ---------------------------------------------------------------------------------------------------

		const categoryManufacturerForm = JSON.parse(JSON.stringify({...(this.refCategoryManufacturerForm?.current?.values || {})}));
		formData.category_id = categoryManufacturerForm?.category_id || null;
		formData.extraCategories = categoryManufacturerForm?.extraCategories || null;

		// ---------------------------------------------------------------------------------------------------

		const characteristicsFormExtraValues = JSON.parse(JSON.stringify({...(this.refCharacteristicsForm?.current?.values?.extraValues || {})}))
		const charBannerCilek = this._getCilekCharValue();
		const initExtraValues = this.state?.initExtraValues || {};
		const currentExtraValues = {
			...characteristicsFormExtraValues,
			...(charBannerCilek || {})
		};
		const extraValues = Boolean(JSON.stringify(initExtraValues) === JSON.stringify(currentExtraValues)) ? null : currentExtraValues;
		if (!!extraValues) {
			formData.extraValues = extraValues;
		}

		// ---------------------------------------------------------------------------------------------------

		const imagesForm = JSON.parse(JSON.stringify({...(this.refImagesForm?.current?.values || {})}));
		formData.images = imagesForm.images || [];
		formData.main_photo_id = imagesForm.main_photo_id;
		formData.main_photo_caption = imagesForm.main_photo_caption;

		// ---------------------------------------------------------------------------------------------------

		const additionalCategoriesForm = JSON.parse(JSON.stringify({...(this.refAdditionalCategoriesForm?.current?.values || {})}));
		formData.extraCategories = additionalCategoriesForm?.extraCategories || [];

		// ---------------------------------------------------------------------------------------------------

		// const relatedSimilarProductsForm = this.refRelatedSimilarProductsForm?.current?.values || {};
		// formData.extraRelated = relatedSimilarProductsForm?.extraRelated || [];
		// formData.extraSame = relatedSimilarProductsForm?.extraSame || [];
		delete formData.extraRelated;
		delete formData.extraSame;

		// ---------------------------------------------------------------------------------------------------

		const { isChangedExtraOptions } = this.state;
		if (isChangedExtraOptions) {
			const options = this.refOptionsForm.current.handleGetValues();
			formData.extraOptions = options.map((item) => {
				if ((item?.variants || []).length > 0) {
					item.variants = item?.variants.map((variant) => {
						variant.price_value = String(variant?.price_value || '').replace(/\s/g, '');
						variant.price_old_value = String(variant?.price_old_value || '').replace(/\s/g, '');
						if (!+variant.price_value) {
							variant.price_value = variant.price_old_value;
						}

						return {
							...variant
						}
					})
				};

				return {
					...item,
					sort: Number(item.sort)
				}
			});
		}

		// ---------------------------------------------------------------------------------------------------

		const storesForm = JSON.parse(JSON.stringify({...(this.refStoresForm?.current?.values || {})}));
		formData.extraStores = storesForm?.extraStores || [];

		// ---------------------------------------------------------------------------------------------------
		const { imageDots } = this.state;
		formData.product_set_image_dots = (imageDots || [])
			.map(( _item ) => {
				return {
					..._item,
					dots: (_item?.dots || []).map((_dot) => {
						return {
							..._dot,
							top: _dot.top,
							left: _dot.left,
						};
					})
				}
			});


		// Файлы
		const filesForm = JSON.parse(JSON.stringify({...(this.refFilesForm?.current?.values)}));
		formData.file_id = "";
		if (filesForm.file_id && typeof filesForm.file_id === "object") {
			const fileInfo = await filesUpload({
				file: filesForm.file_id
			});
			if (fileInfo?.error) {
				Notification({
					message: fileInfo?.error,
					type: NotificationTypes.error?.data?.message || "Файл не был загружен. "
				})
			} else {
				formData.file_id = fileInfo?.file?.id
			}
		}
		if (filesForm.file_id && typeof filesForm.file_id === "number") {
			formData.file_id = filesForm.file_id;
		}


		let files = [];
		if ((filesForm?.files || []).length > 0) {
			await Promise.all(filesForm?.files.map(async (file) => {
				if (file && !file?.id && typeof file === "object") {
					const fileInfo = await filesUpload({
						file: file
					});
					if (fileInfo?.error) {
						Notification({
							message: fileInfo?.error,
							type: NotificationTypes.error?.data?.message || "Файл не был загружен. "
						})
					} else {
						files.push(fileInfo?.file?.id)
					}
				}
				if (file && file?.id) {
					files.push(file?.id)
				}
			}))
		}
		formData.files = files;

		// ---------------------------------------------------------------------------------------------------

		// Видео ссылки
		const videosForm = JSON.parse(JSON.stringify({...(this.refVideos?.current?.values || {})}));
		formData.video_link = (videosForm?.video_link || []).filter((t) => t.value);
		// ---------------------------------------------------------------------------------------------------

		// Баннер для коллекции cilek
		const bannerImageId = this.refImagesForm.current.values?.bannerImageId;
		let banner_images = [];
		if (bannerImageId) {
			banner_images = [{imageId: bannerImageId, caption: ''}];
		}
		formData.banner_images = banner_images;
		// ---------------------------------------------------------------------------------------------------

		return formData
	}
	_getCilekCharValue = () => {

		const { shopCharacteristicGroups } = this.state;
		let _items = [];
		shopCharacteristicGroups.map((_item) => {
			(_item?.characteristicAssignments || []).map((_t) => {
				_items.push(_t)
			})
		});
		const _item = _items.find((t) => Boolean(t?.characteristic?.slug === 'tip-bannera'))?.characteristic || null;
		if (!_item) {
			return null
		}

		const values = this.refImagesForm.current.values;
		const charValue = values?.extraValues[_item.id];

		return {
			[_item.id]: charValue
		}
	}

	_routeProductsPage = () => {
    const searchUrl = localStorage.getItem("products-filter-object") || "";
		this.props.history.push(`/products?${searchUrl}`)
	}
	_routeDuplicateProductEditPage = (id) => {
		this.props.history.push(`/products/edit/${id}`);
		window.location.reload();
	}
	_routeGoBack = () => {
		this.props.history.goBack();
	}

	changeIsChangedExtraOptions = () => {
		if (this.state.isChangedExtraOptions) {
			return
		}
		this.setState({ isChangedExtraOptions: true });
	}

	eventEditProductStart = async () => {
		clearTimeout(this.timeOutEventEdit);
		await agent.get(`/admin/api-shop-products/${this.state.product?.id}?ngrestCallType=update&fields=id`);
		this.timeOutEventEdit = setTimeout(async () => {
			await this.eventEditProductStart();
		}, 5 * 1000);
	}
	eventEditProductEnd = async () => {
		clearTimeout(this.timeOutEventEdit);
	}

	changeInitProductCharacteristics = (val) => {
		this.setState({ initProductCharacteristics: val })
	}

	render() {
		const {
			product,
			activeTab,
			editError,
			productId,

			imageDots,

			isLoad,

			brandId,
			categoryId,
			extraCategories,
			sberbankTaxType,
			shopCharacteristicGroups,
			characteristicGroupIds,

			isError,
			isShowBackdrop,
			isCreatedProduct
		} = this.state;
		const {
			userRoles
		} = this.props;

		if (!!isLoad) {
			return (
				<></>
			)
		}

		return (
			<>
				<HeaderProductEdit
					product={product}
					onBack={this._routeGoBack}
				/>

				{Boolean(!isError) && (
					<Box>
						<Grid container spacing={2}>
							<Grid item xs={2}>
								<Navigations
									active={activeTab}
									list={navigationList}
									editError={editError}
									isCollection={Boolean(product?.is_product_set)}

									onChange={this.onChangeActiveTab.bind(this)}
								/>
							</Grid>
							<Grid item xs={10}>
								<ControlProductEdit
									userRoles={userRoles}
									duplicateProduct={this.duplicateProduct}
									onEditProduct={this.editProduct}
									onDeleteProduct={this.deleteProducts.bind(this, false)}
								/>
								<NavigationTab visible={Boolean(activeTab === "main")}>
									<MainForm
										innerRef={this.refMainForm}
										initialValues={product}
										sberbankTaxType={sberbankTaxType}
										isCreatedProduct={isCreatedProduct}
										brandId={brandId}
										characteristicGroupIds={characteristicGroupIds}
										isCollection={Boolean(product?.is_product_set)}

										onChangeProductExtraWebsites={this.changeProductExtraWebsites}
										onChangeProductCharacteristicGroup={this.changeProductCharacteristicGroup}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "seo")}>
									<SeoForm
										innerRef={this.refSeoForm}
										initialValues={product}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "marketplaces")}>
									<MarketplacesForm
										innerRef={this.refMarketplacesForm}
										initialValues={product}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "additionally")}>
									<AdditionallyForm
										innerRef={this.refAdditionallyForm}
										initialValues={product}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "package")}>
									<PackageForm
										innerRef={this.refPackageForm}
										initialValues={product}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "category-manufacturer")}>
									<CategoryManufacturerForm
										innerRef={this.refCategoryManufacturerForm}
										initialValues={product}
										categoryId={categoryId}
										brandId={brandId}
										extraCategories={extraCategories}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "characteristics")}>
									<Characteristics
										innerRef={this.refCharacteristicsForm}
										initialValues={product}
										sections={shopCharacteristicGroups}
										visible={Boolean(activeTab === "characteristics")}
										productId={productId}

										updateCharacteristicGroup={this.getCharacteristicGroup}
										onChangeInitProductCharacteristics={this.changeInitProductCharacteristics}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "images")}>
									<ImagesForm
										innerRef={this.refImagesForm}
										initialValues={product}
										characteristics={shopCharacteristicGroups}
										initCharacteristics={this.state.initProductCharacteristics}
										extraWebsites={this.state.extraWebsites}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "additional-categories")}>
									<AdditionalCategoriesForm
										innerRef={this.refAdditionalCategoriesForm}
										initialValues={product}
										extraCategories={extraCategories}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "related-similar-products")}>
									<RelatedSimilarProductsForm innerRef={this.refRelatedSimilarProductsForm}
									                            initialValues={product}
									                            extraCategories={extraCategories}/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "stores")}>
									<StoresForm innerRef={this.refStoresForm} initialValues={product}/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "options")}>
									<OptionsForm
										ref={this.refOptionsForm}
										product={product}
										initialValues={product?.extraOptions || []}
										isActiveTab={Boolean(activeTab === "options")}

										onChangeExtraOptionPresets={this.onChangeExtraOptionPresets}
										onChangeIsChangedExtraOptions={this.changeIsChangedExtraOptions}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "files")}>
									<FilesForm innerRef={this.refFilesForm} initialValues={product}/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "products-collection")}>
									<ProductsCollection
										product={product}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "image-annotations")}>
									<ImageAnnotations
										annotations={imageDots}
										innerRef={this.refImageAnnotations}
										initialValues={product}
                    isVisible={Boolean(activeTab === "image-annotations")}
										extraWebsites={this.state.extraWebsites}

										onChange={this.updateImageDots}
									/>
								</NavigationTab>
								<NavigationTab visible={Boolean(activeTab === "videos")}>
									<VideosForm
										innerRef={this.refVideos}
										initialValues={product}
                    isVisible={Boolean(activeTab === "videos")}
									/>
								</NavigationTab>
							</Grid>
						</Grid>
					</Box>
				)}

				{Boolean(isError) && (
					<Box bgcolor="error">
						<Typography variant="h1" color="error">
							Товар не найден
						</Typography>
					</Box>
				)}

				<DialogConfirmation ref={this.refDialogConfirmation}/>


				<Backdrop open={isShowBackdrop}>
					<CircularProgress color="primary"/>
				</Backdrop>
			</>
		);
	}
}

const NavigationTab = (props) => {
	const {visible, children} = props;

	return (
		<Box display={!visible ? 'none' : 'block'}>
			{children}
		</Box>
	)
}

export default ProductEdit
